
.hero {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 100px 50px;
    background-color: #f4f4f4;
    min-height: calc(100vh - 80px); /* Subtracting navbar height */
  }
  
  .hero-content {
    flex: 1;
    max-width: 600px;
  }
  
  .hero h1 {
    font-size: 3rem;
    margin-bottom: 10px;
    color: #333;
  }
  
  .hero h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #666;
  }
  
  .hero p {
    font-size: 1.2rem;
    margin-bottom: 30px;
    color: #777;
  }
  
  .cta-button {
    display: inline-block;
    padding: 12px 24px;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    font-size: 1.1rem;
    transition: background-color 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: #0056b3;
  }
  
  .hero-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .hero-image img {
    max-width: 30%;
    height: auto;
    border-radius: 50%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }
  .cta-button,
.project-link,
button {
  transition: transform 0.2s ease, background-color 0.3s ease;
}

.cta-button:hover,
.project-link:hover,
button:hover {
  transform: translateY(-3px);
}

  @media screen and (max-width: 768px) {
    .hero {
      flex-direction: column;
      text-align: center;
      padding: 50px 20px;
    }
  
    .hero-content {
      margin-bottom: 40px;
    }
  
    .hero h1 {
      font-size: 2.5rem;
    }
  
    .hero h2 {
      font-size: 1.3rem;
    }
  
    .hero p {
      font-size: 1rem;
    }
  
    .hero-image img {
      max-width: 250px;
    }
  }