
.skills {
    padding: 80px 20px;
    background-color: #f9f9f9;
  }
  
  .skills h2 {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 40px;
    color: #333;
  }
  
  .skill-category {
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .skill-category h3 {
    font-size: 1.8rem;
    margin-bottom: 20px;
    color: #444;
    text-align: center;
  }
  
  .skills-grid {
    display: grid;
    gap: 20px;
    width: 100%;
    max-width: 900px;
  }
  
  .skill-item {
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .skill-icon {
    font-size: 2rem;
    margin-right: 15px;
    color: #007bff;
    width: 40px;
    text-align: center;
  }
  
  .skill-info {
    flex-grow: 1;
  }
  
  .skill-name {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .skill-bar-container {
    height: 10px;
    background-color: #e0e0e0;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .skill-bar {
    height: 100%;
    background-color: #4caf50;
    border-radius: 5px;
    transition: width 1s ease-in-out;
    position: relative;
  }
  
  .skill-bar::after {
    content: attr(data-level);
    position: absolute;
    right: 0;
    top: -25px;
    background-color: #333;
    color: #fff;
    padding: 2px 6px;
    border-radius: 3px;
    font-size: 0.8rem;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
  .skill-bar:hover::after {
    opacity: 1;
  }
  
  /* Desktop layout */
  @media screen and (min-width: 769px) {
    .skills-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  /* Mobile layout */
  @media screen and (max-width: 768px) {
    .skills-grid {
      grid-template-columns: 1fr;
    }
  }