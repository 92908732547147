
.footer {
    background-color: #333;
    color: #fff;
    padding: 20px;
    text-align: center;
  }
  
  .footer-content {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .footer-content p {
    margin: 10px 0;
  }
  
  .social-links {
    margin-top: 10px;
  }
  
  .social-links a {
    color: #fff;
    margin: 0 10px;
    text-decoration: none;
  }
  
  .social-links a:hover {
    text-decoration: underline;
  }